<template>
  <svg
    v-if="name == 'hamburger'"
    :class="'ic_' + name"
    :style="customStyle"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.18945 12H19.1895"
      stroke="#394150"
      stroke-width="2"
      stroke-linecap="square"
    />
    <path
      d="M5.18945 6H19.1895"
      stroke="#394150"
      stroke-width="2"
      stroke-linecap="square"
    />
    <path
      d="M5.18945 18H19.1895"
      stroke="#394150"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{ default: {},}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
