// @ts-check
import { defineStore, acceptHMRUpdate } from "pinia";
import Session from "../modules/Session";
import { Common } from "../modules/Common";
import { Rest, RestUrl } from "../modules/Rest";
export const useStore = defineStore({
  id: "useStore",
  state: () => ({
    env: 'development',
    isLoading: false,
    mobile: false,
    user: null,
    //wallet
    walletToken:null,
    transferToken:null,
    transferComplete:null,
    swapComplete:null,
    //complete
    popupData:{isVisible:false,type:'',isDetail:false,callback:null},
    myWallet:{},
    rank:0
  }),
  getters: {
    getUser: (state) => state.user,
    getIsLoading: (state) => state.isLoading,
    getMobile: (state) => state.mobile,
    getWalletToken: (state) => state.walletToken,
    getTransferToken: (state) => state.transferToken,
    getTransferComplete: (state) => state.transferComplete,
    getPopupData: (state) => state.popupData,
    getMyWallet: (state) => state.myWallet,
    getSwapComplete: (state) => state.swapComplete,
    getEnv: (state) => state.env,
    getRank: (state) => state.rank,
  },
  actions: {
    setEnv(payload) {
      this.env = payload;
    },
    setIsLoading(payload) {
      this.isLoading = payload;
    },
    setUser(payload) {
      this.user = payload;
    },
    setMobile(payload) {
      this.mobile = payload;
    },
    setWalletToken(payload) {
      this.walletToken = payload;
    },
    setTransferToken(payload) {
      this.transferToken = payload;
    },
    setTransferComplete(payload) {
      this.transferComplete = payload;
    },
    setSwapComplete(payload) {
      this.swapComplete = payload;
    },
    setPopupData(payload) {
      this.popupData = payload;
    },
    setMyWallet(payload) {
      this.myWallet = payload;
    },
    setRank(payload) {
      this.rank = payload;
    },
    hasUser() {
      if (this.user && Object.keys(this.user).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getRankColor(){
      var colors = ['#2A62E7','#1EC198','#CD53CE','#FF5A9E','#FF8971','#FFC359','#C0A975']
      return colors[this.rank]
    },
    logout() {
      Session.clear();
      window.location.href = "/";
    },

    async login() {
      //   const userData = await apiLogin(user, password)

      //   this.$patch({
      //     name: user,
      //     ...userData,
      //   })
    },
  },
});

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
// }
