<template>
  <footer class="p_t_24 p_b_24 b_t_1">
    <div class="responsive_fix">
      <h5 class="f_s_14 f_w_600 c_a0a0">Spark</h5>
      <p class="f_s_11 c_a0a0 p_t_8">The Spark is a mail order broker and is not a party to a mail order. <br/>Therefore, the Spark is not responsible for the goods, transaction information, and transactions.</p>
      <small class="f_s_11 c_a0a0 d_b t_a_l p_t_8">copyright © Spark All rights reserved.</small>
    </div>
  </footer>
</template>

<script>
import Root from "../views/root";
export default {
  extends: Root,
  name: "app-footer",
};
</script>

<style scoped>

</style>
