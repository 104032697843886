<template>
    <svg  :class="'ic_' + name"
    :style="customStyle"
    width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="25" :fill="secondColor"/>
      <path d="M24.749 14L24.6016 14.5011V29.0409L24.749 29.188L31.4982 25.1986L24.749 14Z" :fill="color"/>
      <path d="M24.7492 14L18 25.1986L24.7492 29.1881V22.1309V14Z" :fill="color"/>
      <path d="M24.7491 30.466L24.666 30.5672V35.7466L24.7491 35.9893L31.5024 26.4785L24.7491 30.466Z" :fill="color"/>
      <path d="M24.7492 35.9893V30.466L18 26.4785L24.7492 35.9893Z" :fill="color"/>
      <path d="M24.75 29.1879L31.4991 25.1986L24.75 22.1309V29.1879Z" :fill="color"/>
      <path d="M18 25.1986L24.7491 29.188V22.1309L18 25.1986Z" :fill="color"/>
      </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
