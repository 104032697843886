<template>
  <svg
    v-if="name == 'pencilFill'"
    :class="'ic_' + name"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      d="M3.44759 9.78629L2.69256 13.2739C2.66211 13.5378 2.90802 13.7886 3.17031 13.7517L6.65314 12.9918L11.8094 7.83563L8.60419 4.62969L3.44759 9.78629Z"
      fill="#FF6827"
    />
    <path
      d="M9.38729 3.8466L12.5928 7.05215L14.5727 5.07225L11.3672 1.8667L9.38729 3.8466Z"
      fill="#FF6827"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#9DA3AE",
    },
     strokeWidth:{
        type: String,
      default: "1.6",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
