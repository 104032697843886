<template>
  <svg
    v-if="name == 'wifi'"
    :class="'ic_' + name"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" fill="none" />
    <path
      d="M15.658 25.958C16.7393 24.8709 18.2883 24.1924 19.998 24.1924C21.7078 24.1924 23.2568 24.8778 24.3381 25.958C24.5573 26.1795 24.55 26.1627 24.3235 26.3773L20.4218 30.0262C20.188 30.2478 19.8081 30.2478 19.5743 30.0262L15.6726 26.3773C15.4461 26.1627 15.4388 26.1795 15.658 25.958Z"
      :fill="color"
    />
    <path
      d="M29.639 21.6691C27.1022 19.3759 23.713 17.9756 19.9992 17.9756C16.2853 17.9756 12.8962 19.3759 10.3594 21.6691"
      :stroke="color"
      :stroke-width="strokeWidth"
    />
    <path
      d="M5.00781 16.5172C8.9652 12.8169 14.4785 10.5439 19.9918 10.5439C25.5051 10.5439 31.0184 12.8169 34.9893 16.5172"
      :stroke="color"
      :stroke-width="strokeWidth"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
