<script>
import { Rest, RestUrl } from "../modules/Rest";
import { Common } from "../modules/Common";
import Session from "../modules/Session";
import { mapState, mapActions } from "pinia";
import { useStore } from "../stores/useStore";
import Rules from "../modules/Rules";
import Formatter from "../modules/Formatter";
import svgIcon from "../components/svg";
import appHeader from "../layout/appHeader";
import appFooter from "../layout/appFooter";
import moment from "moment";
export default {
    components: {  svgIcon,appHeader,appFooter, },
    data() {
        return {
            _root: this,
            _common: Common,
            _rules: Rules,
            _rest: Rest,
            _restUrl: RestUrl,
            _formatter: Formatter,
            _session: Session,
            _moment:moment,
        };
    },
    computed: {
        ...mapState(useStore, [
            "getUser",
            "getIsLoading",
            "getMobile",
            "getWalletToken",
            "getTransferToken",
            "getTransferComplete",
            "getPopupData",
            "getMyWallet",
            "getSwapComplete",
            "getEnv",
            "getRank",
           
        ]),
    },
    mounted: function() {},
    created() {
        this.versionCheck((res)=>{
            if(this._session.hasInspection()){
                this.$router.push({
                    path: "/inspection",
                });
                this.$toast.error(this.$t("t_171"));
            }
        });
    },
    methods: {
        ...mapActions(useStore, [
            "setUser",
            "setIsLoading",
            "setMobile",
            "logout",
            "hasUser",
            "setWalletToken",
            "setTransferToken",
            "setTransferComplete",
            "setPopupData",
            "setMyWallet",
            "setSwapComplete",
            "setEnv",
            "setRank",
            "getRankColor"
        ]),
        async versionCheck(callback) {
            var err;
            var self = this;
            try {
                // this._session.clearInspection();
                // if (callback) {
                //     callback();
                // }

                let url = RestUrl.VUE_APP_API + "/maintenance";
                    
                await this._rest.axios(
                        'get',
                        url
                    ).then(response => {
                    if(response){
                        if(response.status !== 200){
                            this.setIsLoading(false);
                            err = response.data;
                            return
                        }
                        response = response.data

                
                        if (response.status == 1) {
                            this._session.setInspection(response.message)
                        }else{
                            this._session.clearInspection();
                        }
                        
                       if (callback) {
                            callback(response);
                        } else {
                            return response;
                        }

                    }
                    })
                    .finally(() => {

                        setTimeout(function() {
                            if (err) {
                                self.$toast.error(err.message);
                            }
                        }, 100);
                    });

            } catch (error) {
                console.log(error);
            }
        },
        async getMeData(callback) {
            var err;
            var self = this;
            // self.logout();
            try {
                let url = RestUrl.VUE_APP_API + "/users/me";

                await this._rest.axios(
                        'get',
                        url
                    ).then(response => {
                    if(response){
                      if(response.status !== 200){
                        this.setIsLoading(false);
                        err = response.data;
                        return
                      }
                      response = response.data

                        if (Object.keys(response).length > 0) {
                            this.setUser(response);
                            if (callback) {
                                callback(response);
                            } else {
                                return response;
                            }
                        }
                    }
                    })
                    .finally(() => {

                        setTimeout(function() {
                            if (err) {
                                self.$toast.error(err.message);
                                self.logout();
                                useStore.$reset()
                            }
                        }, 100);
                    });

            } catch (error) {
                console.log(error);
            }
        },
        async getShopData(callback) {
            var err;
            var self = this;
            try {
                let url = RestUrl.VUE_APP_API + "/shop";
                    
                await this._rest.axios(
                        'get',
                        url
                    ).then(response => {
                    if(response){
                      if(response.status !== 200){
                        this.setIsLoading(false);
                        err = response.data;
                        return
                      }
                      response = response.data

                        if (response.length > 0) {
                            this.setShops(response);
                            if (callback) {
                                callback(response);
                            } else {
                                return response;
                            }
                        }
                    }
                    })
                    .finally(() => {

                        setTimeout(function() {
                            if (err) {
                                self.$toast.error(err.message);
                            }
                        }, 100);
                    });

            } catch (error) {
                console.log(error);
            }
        },
        async getRealShopData(callback) {
            var err;
            var self = this;
            try {
                let url = RestUrl.VUE_APP_API + "/active/campaign";
                    
                await this._rest.axios(
                        'get',
                        url
                    ).then(response => {
                    if(response){
                      if(response.status !== 200){
                        this.setIsLoading(false);
                        err = response.data;
                        return
                      }
                      response = response.data

                        if (response.length > 0) {
                            this.setRealShops(response);
                            if (callback) {
                                callback(response);
                            } else {
                                return response;
                            }
                        }
                    }
                    })
                    .finally(() => {

                        setTimeout(function() {
                            if (err) {
                                self.$toast.error(err.message);
                            }
                        }, 100);
                    });

            } catch (error) {
                console.log(error);
            }
        },
        async getShopPackageData(shopId,callback) {
            var err;
            var self = this;
            try {
                let url = RestUrl.VUE_APP_API + "/shop/"+shopId+"/items/package";

                await this._rest.axios(
                        'get',
                        url
                    ).then(response => {
                    if(response){
                      if(response.status !== 200){
                        this.setIsLoading(false);
                        err = response.data;
                        return
                      }
                      response = response.data

                        if (response.pageRows.length > 0) {
                            if (callback) {
                                callback(response.pageRows.reverse());
                            } else {
                                return response.pageRows.reverse();
                            }
                        }
                    }
                    })
                    .finally(() => {

                        setTimeout(function() {
                            if (err) {
                                self.$toast.error(err.message);
                            }
                        }, 100);
                    });

            } catch (error) {
                console.log(error);
            }
        },
        async getLimitsData(callback) {
            var err;
            var self = this;
            try {
                let url = RestUrl.VUE_APP_API + "/me/limits";

                await this._rest.axios(
                        'get',
                        url
                    ).then(response => {
                    if(response){
                      if(response.status !== 200){
                        this.setIsLoading(false);
                        err = response.data;
                        return
                      }
                      response = response.data

                        if (Object.keys(response).length > 0) {
                            this.setLimits(response);
                            if (callback) {
                                callback(response);
                            } else {
                                return response;
                            }
                        }
                    }
                    })
                    .finally(() => {

                        setTimeout(function() {
                            if (err) {
                                self.$toast.error(err.message);
                            }
                        }, 100);
                    });

            } catch (error) {
                console.log(error);
            }
        },

    },
};
</script>
