<template>
      <svg :class="'ic_' + name"
      :style="customStyle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.8678" cy="7.59635" r="2.66667" :stroke='color' stroke-width="1.77778"/>
      <path d="M6 18.2496C6 16.0383 7.48264 13.126 11.2465 13.126H14.0104" :stroke='color' stroke-width="1.77778"/>
      <path d="M18.4453 11.1396V18.2508" :stroke='color' stroke-width="1.77778" stroke-linejoin="round"/>
      <path d="M22 14.6953L14.8889 14.6953" :stroke='color'  stroke-width="1.77778" stroke-linejoin="round"/>
      </svg>

</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
