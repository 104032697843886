<template>
    <svg 
    :class="'ic_' + name"
    :style="customStyle"
    width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>btn_back_n</title>
    <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="01_지갑_04_토큰-상세_01_일반-지갑" transform="translate(-7.000000, -51.000000)" :stroke="color" stroke-width="2">
            <g id="Navigation-Bar---Hierarchical" transform="translate(0.000000, 44.000000)">
                <g id="btn_back_n" transform="translate(7.000000, 7.000000)">
                    <g id="ic" transform="translate(10.000000, 8.000000)">
                        <polyline transform="translate(3.500000, 7.000000) scale(-1, 1) rotate(-180.000000) translate(-3.500000, -7.000000) " points="7 14 0 7 7 0"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#1E53DA",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
