<template>
  <svg
    v-if="name == 'bodyType'"
    :class="'ic_' + name"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5408 7.73319C22.5408 9.13321 21.4059 10.2681 20.0059 10.2681C18.6059 10.2681 17.4709 9.13321 17.4709 7.73319C17.4709 6.33317 18.6059 5.19824 20.0059 5.19824C21.4059 5.19824 22.5408 6.33317 22.5408 7.73319ZM24.3475 7.73319C24.3475 10.131 22.4037 12.0748 20.0059 12.0748C17.6081 12.0748 15.6643 10.131 15.6643 7.73319C15.6643 5.3354 17.6081 3.3916 20.0059 3.3916C22.4037 3.3916 24.3475 5.3354 24.3475 7.73319ZM18.1096 13.1639L11.9822 14.195L11.4277 14.2883L11.2666 14.8271L8.32422 24.6682L10.0551 25.1858L12.8365 15.8833L18.4094 14.9455L18.1096 13.1639ZM28.0173 14.195L21.8899 13.1639L21.5901 14.9455L27.1658 15.8838L30.0129 25.2522L31.7415 24.7268L28.7317 14.8232L28.569 14.2879L28.0173 14.195ZM14.0989 21.6924V36.6077H15.9055V21.6924H14.0989ZM24.093 36.6077V21.6924H25.8996V36.6077H24.093ZM19.0969 29.9922V36.6594H20.9035V29.9922H19.0969Z"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
