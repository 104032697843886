"use strict";
import { getCurrentInstance } from "vue";

export default {
  /**
   * 세션을 저장한다.
   * @param accessToken 억세스 토큰
   * @param bizId  bizId
   * @param uniqueId uniqueId
   */

  setAccessToken: (accessToken) => {
    localStorage.setItem("sp_access_token", accessToken);
  },
  
  setLang: (code) => {
    localStorage.setItem("sp_lang", code);
  },

  setAuth: (code) => {
    localStorage.setItem("sp_auth", code);
  },
  
  setInspection: (code) => {
    localStorage.setItem("sp_inspection", code);
  },

  /**
   * 세션 존재 여부를 혹인한다.
   * @returns {Boolean} 세션이 존재한다면 true
   */
  hasSession: () => {
    return localStorage.getItem("sp_auth") !== null;
  },

  hasLang: () => {
    return localStorage.getItem("sp_lang") !== null;
  },

  hasInspection: () => {
    return localStorage.getItem("sp_inspection") !== null;
  },


  /**
   * 세션을 초기화한다.
   */
  clear: () => {
    localStorage.removeItem("sp_access_token");
    localStorage.removeItem("sp_lang");
    localStorage.removeItem("sp_auth");
  },

  clearInspection: () => {
    localStorage.removeItem("sp_inspection");
  },

  /**
   * 액세스 토큰을 반환한다.
   * @returns {String} 액세스 토큰
   */
  getAccessToken: () => {
    return localStorage.getItem("sp_access_token");
  },

  /**
   * lang을 반환한다.
   * @returns {String} corLang
   */
  getLang: () => {
    return localStorage.getItem("sp_lang");
  },
  /**
   * auth를 반환한다.
   * @returns {String} corAuth
   */
  getAuth: () => {
    return localStorage.getItem("sp_auth");
  },
  getInspection: () => {
    return localStorage.getItem("sp_inspection");
  },

};
