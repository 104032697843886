<template>
  <svg
    v-if="name == 'siren'"
    :class="'ic_' + name"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      d="M3.33301 7.33335C3.33301 4.75603 5.42235 2.66669 7.99967 2.66669V2.66669C10.577 2.66669 12.6663 4.75603 12.6663 7.33335V13.3334H3.33301V7.33335Z"
      :stroke="color"
      :stroke-width="strokeWidth"
    />
    <path
      d="M2 13.3333L14 13.3333"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#9DA3AE",
    },
     strokeWidth:{
        type: String,
      default: "1.6",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
