<template>
    <svg
      :class="'ic_' + name"
      :style="customStyle"
      width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g  transform="translate(-335.000000, -51.000000)" :fill="color">
              <g id="bar/navigation" transform="translate(0.000000, 44.000000)">
                  <g id="btn_group_n" transform="translate(335.000000, 7.000000)">
                      <g id="Group-4" transform="translate(5.000000, 5.000000)">
                          <circle id="ic_loading_list" transform="translate(2.500000, 17.500000) rotate(-90.000000) translate(-2.500000, -17.500000) " cx="2.5" cy="17.5" r="2.5"></circle>
                          <circle id="ic_loading_list-copy" transform="translate(10.500000, 17.500000) rotate(-90.000000) translate(-10.500000, -17.500000) " cx="10.5" cy="17.5" r="2.5"></circle>
                          <circle id="ic_loading_list-copy-3" transform="translate(18.500000, 17.500000) rotate(-90.000000) translate(-18.500000, -17.500000) " cx="18.5" cy="17.5" r="2.5"></circle>
                          <circle id="ic_loading_list-copy-2" transform="translate(10.500000, 2.500000) rotate(-90.000000) translate(-10.500000, -2.500000) " cx="10.5" cy="2.5" r="2.5"></circle>
                          <rect id="line-copy-2" :stroke="color" stroke-width="0.4" x="10" y="4" width="1" height="12"></rect>
                          <rect id="line-copy-2" :stroke="color" stroke-width="0.4" x="2" y="10" width="17" height="1"></rect>
                          <rect id="line-copy-3" :stroke="color" stroke-width="0.4" x="2" y="11" width="1" height="5"></rect>
                          <rect id="line-copy-4" :stroke="color" stroke-width="0.4" x="18" y="11" width="1" height="5"></rect>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#2A62E7",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
