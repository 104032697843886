<template>
  <svg
    v-if="name == 'markKakao'"
    :class="'ic_' + name"
    :style="customStyle"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#FEE500" />
    <path
      d="M11.9993 7.54688C9.06965 7.54688 6.69629 9.35509 6.69629 11.5846C6.73303 12.2957 6.96863 12.9832 7.37727 13.5718C7.78591 14.1604 8.35185 14.6274 9.01311 14.9217L8.42401 17.0403C8.41689 17.0673 8.41527 17.0954 8.41926 17.1231C8.42325 17.1507 8.43277 17.1773 8.44727 17.2013C8.46177 17.2253 8.48097 17.2462 8.50376 17.2629C8.52654 17.2796 8.55247 17.2918 8.58005 17.2986C8.61006 17.3055 8.64118 17.3063 8.67152 17.301C8.70185 17.2956 8.73075 17.2843 8.75644 17.2676L11.3345 15.5924C11.5527 15.6135 11.7743 15.6257 11.9993 15.6257C14.9279 15.6257 17.3024 13.8174 17.3024 11.5879C17.3024 9.35842 14.9279 7.5502 11.9993 7.5502"
      fill="black"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#4D5562",
    },
    strokeWidth:{
        type: String,
      default: "1",
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
