<template>
  <header class="header_wrap color" :class="type == 'N' && 'normal'">
    <!-- :style="type &&{boxShadow:'none'}" -->
      <button type="button" class="btn_common btn_icon btn_hover" @click="()=>{
        if($route.path == '/me'){
          $router.push({path:'/setting'})
        }else if($route.path == '/setting'){
          $router.push({path:'/'})
        }else{
          $router.back()
        }
        }">
      <svgIcon
        :name="'arrowBack'" :color="type == 'N' ?'#000000':'#ffffff'"/>
      </button>
      <h2 :class="uppercase && 'text_upper'">{{ title }}</h2>
      <slot name="inner_right"></slot>
    
  </header>
  
</template>

<script>
import { Rest, RestUrl } from "../modules/Rest";
import { Common } from "../modules/Common";
import svgIcon from "../../src/components/svg";
import Session from "../modules/Session";
import Root from '../views/root.vue'
import { mapState, mapActions } from "pinia";
import { useStore } from "../stores/useStore";
export default {
  components: { svgIcon, },
  name: "appHeader",
  props: {
    title: {
        type: String,
        default: '',
    },
    type:{
      type:String,
      default:'N'
    },
    uppercase:{
      type:Boolean,
      default:false
    },
  },
  watch: {
    defaultIndex() {
        this.title = this.title;
        this.uppercase = this.uppercase;
        this.type = this.type;
    },
  },
  data() {
    return {
      root: this,
      common: Common,
      popupActive: null,
    };
  },
  computed: {
      ...mapState(useStore, [
          "getPopupData",
          "getMyWallet",
          "getEnv"
      ]),
  },
  created() {
    // console.log(this.$route)
    if (Session.hasSession()) {
    }
  
  },
  
  methods: {
      ...mapActions(useStore, [
          "setIsLoading",
          "setPopupData",
          "setMyWallet"
      ]),

  },
  
  unmounted() {
  },
};
</script>
<style scoped>
 @keyframes flash { 0%, to { opacity: 1; } 50% { opacity: 0.5; } }
 
 .flash_buy {
     animation: flash 2s infinite ease-in-out;
 }
 .flash_sell {
     animation: flash 1s infinite ease-in-out;
 }


</style>
