<template>
  <svg
    v-if="name == 'privateShowerRoom'"
    :class="'ic_' + name"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      d="M35 5.04883L35 6.71549L20.6348 6.71549L20.6348 5.04883L35 5.04883Z"
      :fill="color"
    />
    <rect x="20.0117" y="5.03223" width="1.66667" height="30" :fill="color" />
    <path
      d="M10.3956 5.87305H16.3504L20.8334 10.5209V17.0835L16.3504 12.3639L13.542 14.9742L7.45898 8.85045L10.3956 5.87305Z"
      :stroke="color"
      :stroke-width="strokeWidth"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.61923 18.6289L3.5645 19.6836L2.50977 18.6289L3.5645 17.5742L4.61923 18.6289ZM6.72869 16.5194L5.67396 17.5742L4.61923 16.5194L5.67396 15.4647L6.72869 16.5194ZM7.78342 15.4647L6.72869 14.41L7.78342 13.3552L8.83815 14.41L7.78342 15.4647Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.31298 13.3585L3.0212 14.1043L2.27539 12.8125L3.56717 12.0667L4.31298 13.3585ZM5.60475 12.6127L4.85894 11.3209L6.15072 10.5751L6.89653 11.8669L5.60475 12.6127Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2539 15.2949L9.5081 16.5867L10.7999 17.3325L11.5457 16.0407L10.2539 15.2949ZM9.30826 19.9161L10.0541 18.6243L8.76229 17.8785L8.01648 19.1703L9.30826 19.9161Z"
      :fill="color"
    />
    <path
      d="M35 33.3652L35 35.0319L20.6348 35.0319L20.6348 33.3652L35 33.3652Z"
      :fill="color"
    />
    <rect x="23.7793" y="17.416" width="1.66667" height="5" :fill="color" />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
     strokeWidth:{
        type: String,
      default: "1.49162",
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{ default: {},}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
