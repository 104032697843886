<template>
      <svg :class="'ic_' + name"
      :style="customStyle" width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-20.000000, -454.000000)" stroke="#FFFFFF">
            <g id="list" transform="translate(0.000000, 241.000000)">
                <g transform="translate(20.000000, 81.000000)" id="#03">
                    <g transform="translate(0.000000, 132.000000)">
                        <g id="ic_state_swap" transform="translate(18.000000, 18.000000) scale(1, -1) translate(-18.000000, -18.000000) ">
                            <g id="ic" transform="translate(17.634750, 17.788550) scale(1, -1) rotate(-90.000000) translate(-17.634750, -17.788550) translate(9.845700, 7.153700)">
                                <polygon id="Fill-1" fill="#FFFFFF" points="5.8513 21.2697 9.6993 18.4197 5.8513 15.5687"></polygon>
                                <polygon id="Fill-2" fill="#FFFFFF" points="9.7267 4.11002488e-15 5.8787 2.85 9.7267 5.701"></polygon>
                                <g id="Group-11" transform="translate(0.000000, 2.849500)" stroke-width="2">
                                    <path d="M3.291,1.437 C1.303,2.848 0,5.163 0,7.786 C0,10.408 1.303,12.723 3.291,14.133" id="Stroke-3"></path>
                                    <path d="M12.2871,14.1333 C14.2751,12.7233 15.5781,10.4073 15.5781,7.7853 C15.5781,5.1623 14.2751,2.8483 12.2871,1.4373" id="Stroke-5"></path>
                                    <path d="M3.291,14.1333 C4.562,15.0343 6.111,15.5703 7.789,15.5703" id="Stroke-7"></path>
                                    <path d="M12.2871,1.437 C11.0161,0.536 9.4671,3.99304661e-15 7.7891,3.99304661e-15" id="Stroke-9"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
