<template>
  <svg
    v-if="name == 'profile'"
    :class="'ic_' + name"
    width="184"
    height="184"
    viewBox="0 0 184 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92 184C142.81 184 184 142.81 184 92C184 41.1898 142.81 0 92 0C41.1898 0 0 41.1898 0 92C0 142.81 41.1898 184 92 184Z"
      fill="#E5E7EB"
    />
    <path
      d="M183.5 92C183.5 142.534 142.534 183.5 92 183.5C41.4659 183.5 0.5 142.534 0.5 92C0.5 41.4659 41.4659 0.5 92 0.5C142.534 0.5 183.5 41.4659 183.5 92Z"
      stroke="#171A22"
      stroke-opacity="0.08"
    />
    <path
      d="M41.4 103.57C41.4 102.951 41.7929 102.4 42.3783 102.199L91.7634 85.1814C91.9166 85.1286 92.083 85.1286 92.2362 85.1814L141.622 102.199C142.207 102.4 142.6 102.951 142.6 103.57V154.1L41.4 154.1V103.57Z"
      fill="white"
    />
    <circle cx="92" cy="52.9" r="25.3" fill="white" />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#E5E7EB",
    }, strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
