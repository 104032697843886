<template>
  <svg :class="'ic_' + name"
  :style="customStyle"
  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ic_set</title>
    <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="01_지갑_01_목록_01_기본" transform="translate(-331.000000, -63.000000)" stroke="#FFFFFF">
            <g id="bar/title" transform="translate(0.000000, 44.000000)">
                <g id="btn_set_n" transform="translate(321.000000, 9.000000)">
                    <g id="ic" transform="translate(12.000000, 12.000000)">
                        <path d="M16.3636364,10 C16.3636364,13.5145989 13.5145989,16.3636364 10,16.3636364 C6.48540107,16.3636364 3.63636364,13.5145989 3.63636364,10 C3.63636364,6.48540107 6.48540107,3.63636364 10,3.63636364 C13.5145989,3.63636364 16.3636364,6.48540107 16.3636364,10 Z" id="Stroke-1" stroke-width="2.27272727"></path>
                        <g id="Group" stroke-width="2.72727273">
                            <line x1="16.8" y1="10.025" x2="20" y2="10.025" id="Stroke-3"></line>
                            <line x1="-3.71420066e-14" y1="10.025" x2="3.2" y2="10.025" id="Stroke-5"></line>
                            <line x1="14.8084" y1="5.1916" x2="17.0712" y2="2.9288" id="Stroke-7"></line>
                            <line x1="2.92892" y1="17.07108" x2="5.19172" y2="14.80828" id="Stroke-9"></line>
                            <line x1="10" y1="3.2" x2="10" y2="6.6209664e-14" id="Stroke-11"></line>
                            <line x1="10" y1="20" x2="10" y2="16.8" id="Stroke-13"></line>
                            <line x1="5.1916" y1="5.1916" x2="2.9288" y2="2.9288" id="Stroke-15"></line>
                            <line x1="17.07108" y1="17.07108" x2="14.80828" y2="14.80828" id="Stroke-17"></line>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script lang="js">
export default {
props: {
  name: {
    type: String,
    default:null,
  },
  color: {
    type: String,
    default: "#ffffff",
  },
  strokeWidth:{
      type: String,
    default: "2",
  },
  secondColor: {
    type: String,
    default: "#212936",
  },
  sizeType:{
      type: String,
      default: '24',
  },
customStyle:{default:{}}
},
watch: {
},
data() {
  return {
    _root: this,
  };
},
methods: {},
unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
