<template>
  <svg
    v-if="name == 'location'"
    :class="'ic_' + name"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.33345 2C6.55675 2 3.29462 2.87285 3.33365 7.02455C3.35731 9.54226 5.24285 11.4909 7.15427 13.4663C7.33236 13.6503 7.51068 13.8346 7.68774 14.0196C8.01423 14.3607 8.56096 14.3679 8.89433 14.0334C9.01367 13.9137 9.13345 13.7941 9.25324 13.6745C11.2783 11.6521 13.3085 9.6246 13.333 7.02455C13.372 2.87285 10.1101 2 8.33345 2ZM9.33331 5.99998H7.33331V7.99998H9.33331V5.99998Z"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#E5E5E5",
    },
     strokeWidth:{
        type: String,
      default: "1",
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
