<template>
    <svg class="'ic_' + name"
    :style="customStyle"
    width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.491 21H14.579V13.467H10.736V21H3.509C3.509 20.91 3.5 20.82 3.5 20.73C3.5 17.454 3.5 14.187 3.5 10.92C3.5 10.803 3.563 10.641 3.644 10.569C6.569 8.058 9.494 5.556 12.419 3.054C12.437 3.036 12.464 3.027 12.5 3C12.554 3.036 12.608 3.072 12.662 3.117C15.56 5.592 18.458 8.076 21.347 10.56C21.428 10.632 21.5 10.767 21.5 10.875C21.5 14.205 21.5 17.526 21.5 20.856C21.5 20.901 21.5 20.946 21.491 21Z" :fill='color'/>
    </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#9DA3AE",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
