<template>
    <router-view v-if="routeLoading === true">
    </router-view>
    <div v-if="getIsLoading" class="isLoading">
      <svgIcon :name="'puff'" :customStyle="{width:'60px',height:'60px'}"/>
    </div>
</template>

<script>
import { RouterView } from "vue-router";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Root from "./views/root";
import $ from "jquery";
import svgIcon from "./components/svg";
import { uuid } from "vue3-uuid";
export default {
    name: "App",
    extends: Root,
    components: { RouterView,svgIcon},
    data: function() {
        return {
            routeLoading: false,
        };
    },
    computed: {},
    async created() {
        var self = this;
        
        //모바일 여부 확인
        // if(this.$isMobile()){

        // }else{
        //   this.$router.push({ path: '/login' })
        // }

        //언어 고정
        // if (this._session.hasLang() === true) {
        //     this.$i18n.locale = this._session.hasLang()
        // } else {
        //     this._session.setLang(this.$t('lang'))
        // }

        // let firebaseConfig = null;
        
        // if(process.env.VUE_APP_ENV == 'global'){
        //     firebaseConfig = {
        //         apiKey: "AIzaSyDSmnKKbGSnT13E_juLbC5zlPTVQjrK3C4",
        //         authDomain: "collectors-bet.firebaseapp.com",
        //         projectId: "collectors-bet",
        //         storageBucket: "collectors-bet.appspot.com",
        //         messagingSenderId: "240849393651",
        //         appId: "1:240849393651:web:d96f549e753c9f46236486",
        //         measurementId: "G-48MR92BHJ3"
        //     };
        // }else{
        //     firebaseConfig = {
        //         apiKey: "AIzaSyAzpmJHU9DpBvR_STF_Qio7Kki6Dd1o1jQ",
        //         authDomain: "collectors-inc-673f2.firebaseapp.com",
        //         projectId: "collectors-inc-673f2",
        //         storageBucket: "collectors-inc-673f2.appspot.com",
        //         messagingSenderId: "471353694790",
        //         appId: "1:471353694790:web:b2e9a71c0a1af79d694847",
        //         measurementId: "G-ZTDLHPPZR2"
        //     };
        // }
        // // Initialize Firebase
        // if(firebaseConfig !=null){
        //     const app = initializeApp(firebaseConfig);
        //     const analytics = getAnalytics(app);
        // }
       
        
        if(process.env.VUE_APP_ENV){
            this.setEnv(process.env.VUE_APP_ENV);
            // this.setEnv('global');
        }
        if (this._session.hasSession()) {
          this.getMeData(() => {
            this.routeLoading = true;
          });
        } else {
            this.routeLoading = true;
        }

        if (this._session.hasLang()) {
          this.$i18n.locale = this._session.getLang()
        } 

        this.versionCheck((res)=>{
            if(self.routeLoading == true && self._session.hasInspection()){
                self.$router.push({
                    path: "/inspection",
                });
            }
        });
    },
    methods: {
        handleResize(event) {
            if (window.innerWidth >= 376) {
                if ($("body").hasClass("mobile")) {
                    $("body").removeClass("mobile");
                    this.setMobile(false);
                }
            } else {
                if (!$("body").hasClass("mobile")) {
                    $("body").addClass("mobile");
                    this.setMobile(true);
                }
            }
        },
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    },
};
</script>

<style scoped>
.isLoading {
    position: fixed;
    inset: 0px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    display: flex;
    left: 0;top:0;right:0;bottom:0
}
</style>
