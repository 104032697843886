<template>
  <template v-if="name == 'star'">
    <svg
      :class="'ic_' + name"
      :style="customStyle"
      width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-148.000000, -186.000000)" :fill="color">
            <g id="info" transform="translate(20.000000, 128.000000)">
                <g transform="translate(98.000000, 58.000000)">
                    <polygon points="40.9997313 0 37.6008549 7.24170434 30 8.40312307 35.5 14.0404253 34.2017098 22 40.9997313 18.2419869 47.7982902 22 46.5 14.0404253 52 8.40312307 44.3991451 7.24170434 41 0"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
  </template>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#E0E0E0",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
