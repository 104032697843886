<template>
  <svg 
  :class="'ic_' + name"
    :style="customStyle"
    width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.66602 15.834L13.3327 10.0007L6.66602 4.16732" 
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"/>
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#E6E6E6",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
