import VueRouter, { createRouter, createWebHistory } from "vue-router";
import Session from "../modules/Session";
import { Common } from "../modules/Common";
import { useStore } from "../stores/useStore";


const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("../layout/mainLayout"),
      children: [
        {
          path: "/:pathMatch(.*)*",
          name: "NotFound",
          component: () => import("../views/error_404"),
        },
        {
          path: "/inspection",
          name: "inspection",
          component: () => import("../views/inspection"),
        },
        {
          path: "/login",
          meta: { isAuth: true },
          component: () => import("../views/login"),
        },
        {
          path: "/signup",
          meta: { isAuth: true },
          component: () => import("../views/signup"),
        },
        {
          path: "/find/password",
          meta: { isAuth: true },
          component: () => import("../views/findPassword"),
        },
        {
          path: "/home",
          component: () => import("../views/wallet"),
        },
        {
          path: "/groupvolume",
          component: () => import("../views/groupVolume"),
        },
        {
          path: "/orgchart",
          component: () => import("../views/orgChart"),
        },
        {
          path: "/wallet/:tokenId",
          name: "walletDetail",
          component: () => import("../views/walletDetail"),
        },
        {
          path: "/wallet/history/:transactionId",
          name:"walletHistory",
          component: () => import("../views/walletHistory"),
        },
        {
          path: "/wallet/deposit",
          component: () => import("../views/walletDeposit"),
        },
        {
          path: "/wallet/transfer",
          component: () => import("../views/transferAddress"),
        },
        {
          path: "/wallet/transfer/form",
          component: () => import("../views/transferForm"),
        },
        {
          path: "/wallet/transfer/password",
          component: () => import("../views/transferPassword"),
        },
        {
          path: "/wallet/transfer/complete",
          component: () => import("../views/transferComplete"),
        },
        
        {
          path: "/staking/view",
          name: "stakingView",
          component: () => import("../views/stakingView"),
        },
        {
          path: "/staking/history/:packageId",
          name:"stakingHistory",
          component: () => import("../views/stakingHistory"),
        },
        {
          path: "/staking/products",
          component: () => import("../views/stakingProducts"),
        },
        {
          path: "/staking/add/:productId",
          name:"stakingAdd",
          component: () => import("../views/stakingAdd"),
        },
        {
          path: "/setting",
          component: () => import("../views/setting"),
        },
        {
          path: "/me",
          component: () => import("../views/myInformation"),
        },
        {
          path: "/notices",
          component: () => import("../views/noticeList"),
        },
        {
          path: "/notices/:noticeId",
          name:"noticeDetail",
          component: () => import("../views/noticeDetail"),
        },
        {
          path: "/change/email",
          component: () => import("../views/changeEmail"),
        },
        {
          path: "/change/name",
          component: () => import("../views/changeName"),
        },
        {
          path: "/change/password/login",
          component: () => import("../views/changeLoginPassword"),
        },
        {
          path: "/change/password/transaction",
          component: () => import("../views/changeTransfPassword"),
        },
        {
          path: "/languages",
          component: () => import("../views/langEdit"),
        },
        {
          path: "/rank",
          component: () => import("../views/rank"),
        },
    
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (Session.hasInspection()) {
    if(to.path != "/inspection"){
      next({
        path: "/inspection",
      });
    }else{
      next();
    }
  }else if (Session.hasSession()) {
    //auth 거르고
    if (to.meta.isAuth === undefined) {
      next();
    } else {
      next({ name: "NotFound" });
    }
  } else {
    if (to.meta.isAuth === true) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  }
  
  Common.windowScrollTop();
});
export default router;
