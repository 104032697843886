<template>
  <svg
    v-if="name == 'towelRental'"
    :class="'ic_' + name"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      d="M12.4766 29.2299V33.9454H32.5791V7.46875C32.5791 6.55457 31.838 5.81348 30.9238 5.81348H15.0966"
      :stroke="color"
      key=""
      :stroke-width="strokeWidth"
    />
    <path
      d="M26.9756 13.1749V28.9614H7.69922V9.12402C7.69922 7.29566 9.1814 5.81348 11.0098 5.81348H26.0257"
      :stroke="color"
      :stroke-width="strokeWidth"
    />
    <path
      d="M8.11328 22.3408H22.5278"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-miterlimit="5.75877"
    />
  </svg>
</template>
<script lang="js">
export default {
  name: "svgIcon",
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "1.6",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{ default: {},}
  },
  watch: {

  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
