<template>
  <svg :class="'ic_' + name"
    :style="customStyle"
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2929 18.9142L13.2929 19L13.2071 19L13 19.2071L12.2929 19.9142L11.5858 19.2071L11.3787 19L11.2929 19L11.2929 18.9142L4.58579 12.2071L6 10.7929L11.2929 16.0858L11.2929 4L13.2929 4L13.2929 16.0858L18.5858 10.7929L20 12.2071L13.2929 18.9142Z" :fill="color"/>
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
