<template>
  <svg
    v-if="name == 'letter'"
    :class="'ic_' + name"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6661 2.6665H1.33301V3.66649L8.01212 8.28337L14.6661 3.66649V2.6665ZM14.6661 5.06953L7.98755 9.71615L1.33301 5.05732V13.333H14.6661V5.06953Z"
      fill="#394150"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#9DA3AE",
    },
     strokeWidth:{
        type: String,
      default: "1.6",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
