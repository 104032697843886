<template>
      <svg :class="'ic_' + name"
    :style="customStyle"
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6293 4.43002C8.57088 4.61698 6.98638 6.34131 7.0189 8.46046C7.05397 10.727 8.92599 12.5996 11.1923 12.6349L13.1353 12.6649C13.8486 12.6757 14.438 13.2656 14.4493 13.9795C14.4547 14.3256 14.3249 14.6484 14.0844 14.8894C13.8439 15.1298 13.5209 15.2593 13.1756 15.2543L7.01876 15.2036C7.01876 15.6121 7.00646 15.8119 7.01876 16.5897C7.03053 17.3674 7.01934 17.6121 7.01912 18.0191L10.8395 18.0332L10.8529 21.3297C11.6061 21.3297 11.5051 21.3174 12.2828 21.3297C13.0606 21.3415 12.9413 21.3289 13.6707 21.3293L13.6552 18.0535C14.5867 17.97 15.4447 17.5729 16.1069 16.9108C16.8711 16.1466 17.2823 15.1207 17.2653 14.0228C17.2481 12.8895 16.7709 11.8546 16.0151 11.0988C15.2593 10.3431 14.2247 9.86617 13.0922 9.84865L11.1492 9.81859C10.4356 9.80755 9.84624 9.21766 9.83494 8.50377C9.82415 7.78988 10.3955 7.21796 11.1089 7.22925L17.0121 7.28823C17.0121 6.7737 17.0244 6.6799 17.0121 5.90213C17.0063 5.51338 17.0121 4.61254 17.0121 4.47245C16.7588 4.47245 16.2244 4.47821 15.8357 4.47246L13.4447 4.45002L13.4311 1.11254C13.4311 1.11254 13.4312 1.15383 13.0034 1.15383C12.5755 1.15383 12.3902 1.15985 12.0011 1.15383C11.2236 1.14181 11.0121 1.15384 10.615 1.15383L10.6293 4.43002Z" :fill='color' />
      </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
