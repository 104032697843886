<template>
    <svg 
    :class="'ic_' + name"
    :style="customStyle"
    width="16px" height="17px" viewBox="0 0 16 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ic</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-180.000000, -132.000000)" stroke="#FFFFFF" stroke-width="2">
            <g id="contents" transform="translate(20.000000, 118.000000)">
                <g id="ic" transform="translate(167.500000, 22.500000) rotate(-90.000000) translate(-167.500000, -22.500000) translate(160.500000, 15.000000)">
                    <polyline transform="translate(7.000000, 11.000000) rotate(-90.000000) translate(-7.000000, -11.000000) " points="10.5 18 3.5 11 10.5 4"></polyline>
                    <line x1="7" y1="-1.62878024e-14" x2="7" y2="15" id="Path"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  unmounted() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
